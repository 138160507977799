












import { Component, Prop, Emit } from "vue-property-decorator";
import TranslatableComponent from "../../lib/mixins/translateable";
import { Breakpoints } from "@/store/modules/layout";
import { namespace } from "vuex-class";

const layout = namespace("layout");

@Component({})
export default class SearchBar extends TranslatableComponent {
  @Prop()
  private placeholderText!: string;

  @Prop()
  private placeholderTextShort!: string;

  @Prop()
  private initialValue: string | undefined;

  @Emit("search-bar-changed")
  private onInput(e: Event): string {
    return (e.target as HTMLInputElement).value;
  }

  @layout.Getter
  public responsiveAtLeast!: (breakpoint: Breakpoints) => boolean;

  get computedSearchPlaceholder(): string {
    return this.responsiveAtLeast(Breakpoints.lg)
      ? `${
          this.placeholderText
            ? this.placeholderText
            : this.translated_component_value("searchtext_long")
        }`
      : `${
          this.placeholderTextShort
            ? this.placeholderTextShort
            : this.translated_component_value("searchtext_short")
        }`;
  }
}
