






























































import UserManagementOverview from "@/components/usermanagement/UserManagementOverview.vue";
import { Component, Watch } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { UserManagementUser } from "@/models/user";
import accessControl from "@/api/access_control";
import { Account } from "@/interfaces/Account";
import AccountFilter from "@/components/form-components/search/AccountFilter.vue";
import { chain } from "lodash";
import SearchBar from "@/components/dossier/SearchBar.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import Page from "@/components/layouts/Page.vue";
import TheHeader from "@/components/TheHeader.vue";

@Component({
  components: {
    UserManagementOverview,
    AccountFilter,
    Loader,
    Page,
    TheHeader,
    SearchBar,
  },
  props: {
    selectedAccountId: String,
  },
})
@Translatable("usermanagement")
export default class ClientsUserManagement extends mixins(
  TranslatableComponent
) {
  public loading: boolean = false;

  private users: UserManagementUser[] = [];

  private accounts: Account[] = [];
  private accountId: string | undefined = undefined;
  private selectionCleared: boolean = false;

  private filter: string = "";
  private filters: ((o: UserManagementUser) => boolean)[] = [];

  get hasUsers(): boolean {
    return this.users.length > 0;
  }

  private onAccountChanged(account: Account | null) {
    this.filters = [];
    this.accountId = account?.id;

    if (account) {
      this.filters.push(
        (o: UserManagementUser): boolean => o.account.id === account?.id
      );
      this.selectionCleared = false;
    } else {
      this.selectionCleared = true;
    }
  }

  @Watch("users")
  private extractAccountsFromUsers() {
    this.accounts = chain(this.users)
      .map((management) => management.account)
      .sortBy("name")
      .sortedUniqBy("id")
      .compact()
      .value();
  }

  onSearchBarChange(value: string) {
    this.filter = value;
  }

  fetchMyAccountUsers() {
    this.loading = true;
    accessControl.usermanagement.contacts
      .contacts()
      .then((data) => {
        if (data.data) {
          this.users = data.data;
        }
      })
      .catch((error) => {
        this.$snotify.error(
          this.$i18n.t("api.loading.error", {
            model: this.$i18n.tc(UserManagementUser.modelTranslationField, 2),
          })
        );
        this.users = [];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private goToUserDetail(user: UserManagementUser) {
    const accountIdQuery = this.selectionCleared
      ? undefined
      : this.accountId ?? this.$props.selectedAccountId;
    this.$router.push({
      name: "usermanagement-detail-edit",
      params: { contactId: user.id },
      query: {
        selectedAccountId: accountIdQuery,
      },
    });
  }

  mounted() {
    this.fetchMyAccountUsers();
  }

  get isLoading(): boolean {
    return this.loading;
  }
}
