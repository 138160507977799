

















import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Role } from "@/models/role";

@Component({})
@Translatable("usermanagement")
export default class AssignedAccessRights extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop() private userId!: string;
  @Prop() private roles!: Role[];

  get hasRoles(): boolean {
    return this.roles.length > 0;
  }
}
