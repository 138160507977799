














import { Component, Vue } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import { namespace } from "vuex-class";
import Cookies from "js-cookie";

const user = namespace("user");

@Component({
  components: {},
})
export default class ScrollIndicator extends TranslatableComponent {
  private showScrollIndicatorBool: boolean = false;

  @user.Getter("isEmployee")
  private isEmployee!: boolean;

  closeScrollIndicator() {
    Cookies.set("hideScrollIndicator", "true", {
      expires: this.isEmployee ? 365 : 30,
    });
    this.showScrollIndicatorBool = false;
  }

  mounted() {
    this.showScrollIndicatorBool = !Cookies.get("hideScrollIndicator");
  }
}
