import { filter } from "lodash";

export const filteredData = <T>(
  data: T[],
  filters: ((o: T) => boolean)[]
): T[] => {
  if (!filters) {
    return data;
  }
  return filters.reduce((a, c) => {
    return filter(a, c);
  }, data);
};
