























import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { Account } from "@/interfaces/Account";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import djlMultiselect from "@/components/form-components/djlMultiselect.vue";

@Component({
  components: { djlMultiselect },
})
@Translatable("form-components.search")
export default class AccountFilter extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop()
  private accounts!: Account[];

  @Prop()
  private customLabel!: (account: Account) => {};

  @Prop({ required: false, default: undefined })
  private selectedAccountId: string | undefined;

  private filter: Account | null = null;

  mounted() {
    if (this.selectedAccountId) {
      const selectedAccount = this.accounts.find(
        (account) => account.id === this.selectedAccountId
      );
      if (selectedAccount) {
        this.filter = selectedAccount;
      }
    }
  }

  @Emit("account-changed")
  private selectedAccountChanged(value: Account | null): Account | null {
    return value;
  }

  @Watch("filter")
  private filterChanged() {
    this.selectedAccountChanged(this.filter);
  }
}
