















































import AssignedAccessRights from "@/components/usermanagement/AssignedAccessRights.vue";
import ScrollIndicator from "@/components/tables/ScrollIndicator.vue";
import Loader from "@/components/Loader.vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import { UserManagementUser } from "@/models/user";
import { filteredData } from "@/components/tables/filter";
import { orderBy } from "lodash";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

@Component({
  components: {
    AssignedAccessRights,
    Loader,
    ScrollIndicator,
  },
})
@Translatable("usermanagement")
export default class UserManagementOverview extends mixins(
  TranslatableComponent
) {
  @Prop()
  private filter!: string;

  @Prop()
  private extraFilters!: ((o: UserManagementUser) => boolean)[];

  @Prop()
  private users!: UserManagementUser[];

  tableUserKey(user: UserManagementUser): string {
    return `row-${user.id}${user.account.id}`;
  }

  @Emit("user-clicked")
  private onUserClicked(user: UserManagementUser) {
    return user;
  }

  @Emit("user-deleted")
  private deleteUser(user: UserManagementUser) {
    return user;
  }

  get filters(): ((o: UserManagementUser) => boolean)[] {
    return [
      ...[
        (o: UserManagementUser) => {
          const filterKeys: Array<keyof typeof o> = ["fullName", "email"];
          return filterKeys.some((key) => {
            if (o[key]) {
              return o[key]
                .toString()
                .toLowerCase()
                .includes(this.computedFilter.toLowerCase());
            }

            return false;
          });
        },
      ],
      ...this.extraFilters,
    ];
  }

  get filteredUsers(): UserManagementUser[] {
    return orderBy(filteredData(this.users, this.filters), "lastName", "asc");
  }

  get computedFilter() {
    return this.filter;
  }
}
